import React, { useState } from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import "./russell-plow.css"

import image1 from "./images/1.jpg"
import image2 from "./images/2.jpg"
// import image3 from "./images/3.jpg"
// import image4 from "./images/4.jpg"

const IMAGE_LIST = [image1, image2]
// const IMAGE_LIST = []

const IndexPage = () => {
  const [index, setIndex] = useState(0)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="container" style={{ flex: 1 }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <img
              style={{ width: 400 }}
              className="main-image"
              src={IMAGE_LIST.filter((_, i) => i === index)[0]}
            ></img>
            <div>
              {IMAGE_LIST.map((img, j) => (
                <a href="javascript:void(0)" onClick={() => setIndex(j)}>
                  <img
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 3,
                      objectFit: "cover",
                    }}
                    key={j}
                    src={img}
                  />
                </a>
              ))}
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <h1 style={{ textAlign: "left" }}>
              Scale Grain Hopper Car and Scale Track Bumper Set
            </h1>
            <p style={{ fontSize: 18 }}>
              Set includes:
              <ul>
                <li>1/8 Scale Grain Hopper Car</li>
                <li>1/8 Scale Track Bumper</li>
              </ul>
              <br />
              <br />
              <b>Price: SOLD</b>
            </p>
            <p>
              <b>Crated product dimensions:</b> 101” × 24” × 33” (L×W×H)
              <br />
              <b>Crated product weight:</b> 374lbs
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
